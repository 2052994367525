import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/skillList.module.scss';

class SkillList extends React.Component {
  render() {
    const mySkills = this.props.skills;

    return (
      <ul className={styles.skillList}>
        {mySkills.map((skill, index) => (
          <li key={index} className={styles.skillList__item}>
            {skill}
          </li>
        ))}
      </ul>
    );
  }
}

SkillList.propTypes = {
  skills: PropTypes.array.isRequired,
};

export default SkillList;
