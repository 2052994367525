import React from 'react';
import SkillList from './skillList';
import logoRV from '../images/logo-rv-main.png';
import logoBoco from '../images/logo-boco.png';
import logoRues from '../images/logo-rues.svg';
import styles from '../styles/experience.module.scss';

const Experience = () => (
  <section>
    <ul className={styles.experience}>
      <li className={styles.job}>
        <div className={styles.job__summary}>
          <div className={styles.job__logoWrapper}>
            <img
              className={styles.job__logo}
              src={logoRV}
              alt="Red Ventures logo"
            />
          </div>
          <span>
            <h3 className={styles.job__company}>Red Ventures</h3>
            <h4 className={styles.job__title}>Senior Front-End Developer</h4>
          </span>
        </div>
        <p className={styles.job__details}>
          <span className={styles.job__duration}>Apr. 2017 - Present.</span>{' '}
          Senior Front-End Developer at one of the world's leading digital
          performance marketing companies. Responsibilities include writing,
          reviewing, and deploying code for web applications - both internal and
          customer-facing - across multiple industries, including deregulated
          energy and home security. I'm involved in strategic planning with our
          business and design teams on a daily basis. I also contribute to L&D
          initiatives on my immediate team and throughout the company.
        </p>
        <SkillList
          skills={[
            'JavaScript',
            'HTML5',
            'CSS3',
            'Sass',
            'Git',
            'Vue.js',
            'React.js',
            'Express',
            'Foundation',
            'jQuery',
            'Node.js',
            'NPM',
            'Gulp',
            'JSON',
            'Hexo',
            'Hugo',
            'Docker',
            'Sketch',
            'Agile/Scrum',
            'WCAG 2.0',
            'JIRA',
            'Slack',
          ]}
        />
      </li>
      <li className={styles.job}>
        <div className={styles.job__summary}>
          <div className={styles.job__logoWrapper}>
            <img
              className={styles.job__logo}
              src={logoBoco}
              alt="Boco Digital Media logo"
            />
          </div>
          <span>
            <h3 className={styles.job__company}>Boco Digital Media</h3>
            <h4 className={styles.job__title}>Junior Developer</h4>
          </span>
        </div>
        <p className={styles.job__details}>
          <span className={styles.job__duration}>Dec. 2014 - Mar. 2017.</span>{' '}
          Junior Developer at a full service creative agency specializing in
          clinical software and gamification. I worked with custom scripts from
          senior developers to build complex site content for proprietary
          products in biotech and pharma. Client-facing UIs and functional
          prototypes were my speciality, but I also helped with API integration.
          This was a remote position, requiring collaboration over Slack,
          Basecamp, and Google Hangouts.
        </p>
        <SkillList
          skills={[
            'JavaScript',
            'HTML5',
            'CSS3',
            'Sass',
            'Pug',
            'Git',
            'Angular (1.x)',
            'jQuery',
            'Bootstrap',
            'NPM',
            'Gulp',
            'Photoshop',
            'Basecamp',
            'Slack',
          ]}
        />
      </li>

      <li className={styles.job}>
        <div className={styles.job__summary}>
          <div className={styles.job__logoWrapper}>
            <img
              className={styles.job__logo}
              src={logoRues}
              alt="Design By The Rues logo"
            />
          </div>
          <span>
            <h3 className={styles.job__company}>Design By The Rues</h3>
            <h4 className={styles.job__title}>Freelance Developer</h4>
          </span>
        </div>
        <p className={styles.job__details}>
          <span className={styles.job__duration}>Oct. 2014 - Sept. 2016.</span>{' '}
          I worked as both an apprentice and a developer to build elegant,
          compatible websites for various clients across a wide range of
          industries, including retail, home services, and non-profit.
        </p>
        <SkillList
          skills={[
            'JavaScript',
            'HTML5',
            'CSS3',
            'Sass',
            'Pug',
            'Git',
            'jQuery',
            'Bootstrap',
            'WordPress',
            'NPM',
            'Grunt',
            'Photoshop',
          ]}
        />
      </li>
    </ul>
  </section>
);

export default Experience;
