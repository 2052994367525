import React from 'react';
import PropTypes from 'prop-types';
import { Cycler } from 'react-text-scrambler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/hero.module.scss';

class Hero extends React.Component {
  render() {
    const occupationList = [
      'front-end developer',
      'CSS devotee',
      'cocktail enthusiast',
    ];

    return (
      <section className={styles.hero}>
        <FontAwesomeIcon icon={faLaptopCode} className={styles.hero__icon} />
        <div className={styles.hero__text}>
          <h1 className={styles.hero__headline}>
            <span className={styles.hero__firstName}>
              {this.props.firstName}
            </span>
            <span className={styles.hero__lastName}>
              {this.props.lastName},
            </span>
            <span className={styles.hero__occupation}>
              {/* front-end developer */}
              <Cycler duration={5000} strings={occupationList} />
            </span>
          </h1>
        </div>
      </section>
    );
  }
}

Hero.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default Hero;
