import React from 'react'

import Layout from '../components/layout'
import Experience from '../components/experience'

const ExperiencePage = props => (
  <Layout>
    <Experience />
  </Layout>
)

export default ExperiencePage
