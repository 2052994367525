import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faTools,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/nav.module.scss';

class Nav extends React.Component {
  state = {
    navClasses: `${styles.nav}`,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  nav = React.createRef();

  handleScroll = () => {
    if (window.pageYOffset === 0) {
      this.setState({ navClasses: `${styles.nav}` });
    } else {
      this.setState({ navClasses: `${styles.nav} ${styles.isScrolled}` });
    }
  };

  render() {
    return (
      <header ref={this.nav} className={this.state.navClasses}>
        <nav>
          <ul className={styles.nav__list}>
            <li className={styles.nav__listItem}>
              <Link
                to="/"
                className={styles.nav__link}
                activeClassName={styles.isActive}
              >
                <FontAwesomeIcon className={styles.nav__icon} icon={faHome} />
                <span className={styles.nav__linkText}>home</span>
              </Link>
            </li>
            <li className={styles.nav__listItem}>
              <Link
                to="/experience/"
                className={styles.nav__link}
                activeClassName={styles.isActive}
              >
                <FontAwesomeIcon className={styles.nav__icon} icon={faTools} />
                <span className={styles.nav__linkText}>experience</span>
              </Link>
            </li>
            <li className={styles.nav__listItem}>
              <Link
                to="/references/"
                className={styles.nav__link}
                activeClassName={styles.isActive}
              >
                <FontAwesomeIcon
                  className={styles.nav__icon}
                  icon={faPaperclip}
                />
                <span className={styles.nav__linkText}>references</span>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Nav;
